.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  width: 60px;
  height: auto;
}

.navigation ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation li {
  margin: 0 1rem;
}

.navigation a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

.navigation a:hover {
  color: #007bff;
}

.banner {
  text-align: center;
  padding: 2rem 0;
  background-color: red;
}

.banner-image {
  max-width: 100%;
  height: auto;
}

.content {
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 2rem;
}

.content h1 {
  color: #2c3e50;
  margin-bottom: 1.5rem;
}

.description {
  line-height: 1.6;
  color: #666;
  margin-bottom: 1rem;
}

.footer {
  margin-top: auto;
  padding: 2rem;
  background-color: #2c3e50;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.social-link {
  transition: transform 0.2s;
}

.social-link:hover {
  transform: scale(1.1);
}

.social-icon {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: 1rem;
  }

  .navigation ul {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .content {
    padding: 0 1rem;
  }
}